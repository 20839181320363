@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");

* {
  margin: 0;
  padding: 0;
}

.about-page-container {
  background-color: #fff;
  color: #000;
}

.nav-conatiner {
  background-color: #000;
}

/*-------------------- content-container -----------------------*/

.content-container {
  margin: 0rem 2.5rem;
}

/*-------------------- about-us-content -----------------------*/

.about-us-content {
  margin: 1.5rem 0rem;
}

.about-us-content p {
  font-weight: 400;
  font-size: 1rem;
  font-family: "Roboto", sans-serif;
}

.about-us-content h1 {
  font-size: 2.5rem;
  font-weight: 700;
  font-family: "Roboto", sans-serif;
}

/*------------------- who-we-are-content -----------------------*/

.who-we-are-content {
  margin: 1.5rem 0rem;
}

.who-we-are-content h4 {
  font-size: 1.6rem;
  font-weight: 600;
  font-family: "Roboto", sans-serif;
  padding: 0.5rem 0;
}

.who-we-are-content p {
  font-size: 1rem;
  font-family: "Roboto", sans-serif;
  font-weight: 400;
}

.who-we-are-content ul {
  margin: 0.8rem 2rem;
}
.who-we-are-content li {
  padding: 0.5rem 0rem;
  font-family: "Roboto", sans-serif;
}

.who-we-are-content span {
  font-weight: 550;
  font-family: "Roboto", sans-serif;
}

/*------------------- what-we-do-content -----------------------*/

.what-we-do-content {
  margin: 1.5rem 0rem;
}

.what-we-do-content h4 {
  font-size: 1.6rem;
  font-weight: 600;
  font-family: "Roboto", sans-serif;
  padding: 0.5rem 0;
}

.what-we-do-content p {
  font-size: 1rem;
  font-family: "Roboto", sans-serif;
  font-weight: 400;
}

.what-we-do-content ul {
  margin: 0.8rem 2rem;
}

.what-we-do-content ul li {
  padding: 0.5rem 0rem;
  font-family: "Roboto", sans-serif;
}

.what-we-do-content ul li span {
  font-weight: 550;
  font-family: "Roboto", sans-serif;
}

/*------------------- our-philosophy-content -----------------------*/

.our-philosophy-content {
  margin: 1.5rem 0rem;
}

.our-philosophy-content h4 {
  font-size: 1.6rem;
  font-weight: 600;
  font-family: "Roboto", sans-serif;
  padding: 0.5rem 0;
}

.our-philosophy-content p {
  font-size: 1rem;
  font-family: "Roboto", sans-serif;
  font-weight: 400;
}

/*------------------- why-choose-us-content -----------------------*/

.why-choose-us-content {
  margin: 1.5rem 0rem;
}

.why-choose-us-content h4 {
  font-size: 1.6rem;
  font-weight: 600;
  font-family: "Roboto", sans-serif;
  padding: 0.5rem 0;
}

.why-choose-us-content ul {
  margin: 0.8rem 2rem;
}

.why-choose-us-content ul li {
  padding: 0.5rem 0rem;
  font-family: "Roboto", sans-serif;
}

.why-choose-us-content ul li span {
  font-weight: 550;
  font-family: "Roboto", sans-serif;
}

/*------------------- ending-para -----------------------*/

.ending-para {
  margin: 1.5rem 0rem;
}

.ending-para p {
  font-size: 1rem;
  font-family: "Roboto", sans-serif;
  font-weight: 400;
}

.foot-conatiner {
  color: #fff;
}
