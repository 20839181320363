body {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

.footer {
  font-size: 0.8rem;
  text-align: center;
  background: linear-gradient(90deg, #3d3072 0%, #423477 100%);
  padding: 1rem;
  margin-top: auto;
}
