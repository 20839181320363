.sticky-whatsapp-button {
  position: fixed;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  background-color: #25d366;
  color: white;
  border: none;
  padding: 10px 10px;
  border-radius: 50%;
  cursor: pointer;
  font-size: 1.5rem;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.5);
  transition: background-color 0.3s;
  display: flex;
  justify-content: center;
  align-items: center;
}

.sticky-whatsapp-button:hover {
  background-color: #128c7e;
}
